import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
      if ($("#web_applicant_high_school_graduate_true").is(':checked') == true) {
          $( "#hssection" ).show();
          $( "#gedsection" ).hide();
      } else {
          $( "#hssection" ).hide();
          $( "#gedsection" ).show();
      };
  }
    yesschool() {
       if (event.target.value == "true") {
         $( "#hssection" ).show();
         $( "#gedsection" ).hide();
        } else {
          $( "#hssection" ).hide();
          $( "#gedsection" ).show();
        }
    }

    noschool() {
       if (event.target.value == "false") {
         $( "#gedsection" ).show();
         $( "#hssection" ).hide();
        } else {
          $( "#gedsection" ).hide();
          $( "#hssection" ).show();
        }
    }
  }
