import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    if ($("#web_applicant_citizen_true").is(':checked') == true) {
        $( "#citizensection" ).hide();
    } else {
        $( "#citizensection" ).show();
    };
}

    yescitizen() {
      if (event.target.value == "true") {
        $( "#citizensection" ).hide();
    }
  }

    nocitizen() {
       if (event.target.value == "false") {
         $( "#citizensection" ).show();
      }
    }


}
